

import {IonContent, IonPage} from '@ionic/vue';
import FixedFooter from "@/components/FixedFooter.vue";
import {store} from "@/store/Store";
import {FooterButtonsBuilder} from "@/mixins/FooterButtonsBuilder";
import router from "@/router";
import {computed, ref} from "vue";
import {useStore} from "vuex";
import {defineComponent} from "@vue/runtime-core";
import { execute } from '@/mixins/LoadingMixin';
import {getJourneySet} from "@/services/JourneySetService";
import { getTemplate } from '@/services/TemplateService';

export default defineComponent({
    name: 'JourneyCopyrightWarning',
    components: {
        IonContent,
        IonPage,
        FixedFooter
    },
    props: {
        journeySetId: {
            type: String,
            required: true
        },
        journeyId: {
            type: String,
            required: true
        }
    },
    async ionViewWillEnter() {
        await this.store.dispatch('app/setPageTitle', this.language.journeySetCreation);
        await execute(async () => {
            const journeySet = await getJourneySet(this.journeySetId);
            const template = await getTemplate(journeySet.templateId);
            this.customContentEnabled = template.enableCustomContent;
            if (this.customContentEnabled == undefined) this.customContentEnabled = true;
            this.contentLoaded = true;
        }, this.language.errors.loadingJourney);
    },
    setup(props: any) {
        const contentLoaded = ref(false);
        const customContentEnabled = ref(false);

        const getNextPage = () => {
            const pageName = customContentEnabled.value ? 'introContent' : 'scriptureContent';
            router.push({
                name: pageName,
                params: {journeySetId: props.journeySetId, journeyId: props.journeyId}
            });
        }

        return {
            contentLoaded,
            customContentEnabled,
            store: useStore(),
            language: computed(() => store.getters['app/language']),
            footerButtons: computed(() => 
                new FooterButtonsBuilder()
                .addClearBackButton()
                .addPrimaryNextButton(getNextPage).create()
            )
        }
    }
});
